import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

import { appURLs } from "@/app/app-urls.mjs";
import { SplashContainer } from "@/shared/ComingSoonSplash.style.jsx";
import ContentContainer from "@/shared/ContentContainer.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";

const overlayFeatureCss = () =>
  css({
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "var(--sp-4)",
    [mobile]: {
      display: "flex",
      flexDirection: "column",
    },
  });

const TITLE = ["overlays:meta.marvelRivals.title", "Marvel Rivals Overlays"];
const DESCRIPTION = [
  "overlays:meta.marvelRivals.description",
  "Elevate your Marvel Rivals experience with our exclusive collection of overlays, designed to enhance your gameplay with real time stats and insights.",
];

const FEATURES = [
  {
    title: [
      "overlays:marvelRivals.feature.realTimeLeaderboard.title",
      "Real-Time Leaderboard",
    ] satisfies Translation,
    description: [
      "overlays:marvelRivals.feature.realTimeLeaderboard.description",
      "See how you stack up against enemies and your teammates. Compare in-game damage, heal, kills, deaths, and assists in real-time.",
    ] satisfies Translation,
  },
  {
    title: [
      "overlays:marvelRivals.feature.healthboard.title",
      "Health Tracker",
    ] satisfies Translation,
    description: [
      "overlays:marvelRivals.feature.healthboard.description",
      "Keep an eye on your team. Know when it's time to heal, and when it's time to push.",
    ] satisfies Translation,
  },
  {
    title: [
      "overlays:marvelRivals.feature.ultimateTracker.title",
      "Ultimate Energy",
    ] satisfies Translation,
    description: [
      "overlays:marvelRivals.feature.ultimateTracker.description",
      "Improve your team coordination with our ultimate energy tracker. Know when your team is ready to unleash their ultimate abilities.",
    ] satisfies Translation,
  },
];

function Overlays() {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <PageHeader title={TITLE} />
      <ContentContainer>
        <section className={SplashContainer()}>
          <figure className="hero-image">
            <img
              src={`${appURLs.CDN}/blitz/ui/img/homepage/marvelRivals/overlay-hero-2.webp`}
              alt="Marvel Rivals Overlays"
              width="1016"
              height="500"
            />
          </figure>
          <div className="meta">
            <h2 className="type-h5">{t("common:features", "Features")}</h2>
            <div className={overlayFeatureCss()}>
              {FEATURES.map((feature, idx) => (
                <div className="flex column gap-3" key={idx}>
                  <h3 className="type-h6">{t(...feature.title)}</h3>
                  <p className="type-body2">{t(...feature.description)}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </ContentContainer>
    </PageContainer>
  );
}

export function meta() {
  return {
    title: TITLE,
    description: DESCRIPTION,
    subtitle: true,
  };
}

export default Overlays;
